<template>
  <div>
    <v-row align="center">
      <v-col cols="auto" class="pr-12">
        <h3>Time Logged</h3>
      </v-col>
      <v-col></v-col>
    </v-row>
    <v-divider></v-divider>
    <v-simple-table>
      <tbody>
        <tr>
          <th>Time Logged Today</th>
          <td>{{ teamMember.time_log_overview.today }}</td>
        </tr>
        <tr>
          <th>Time Logged This Week</th>
          <td>{{ teamMember.time_log_overview.thisWeek }}</td>
        </tr>
        <tr>
          <th>Time Logged This Month</th>
          <td>{{ teamMember.time_log_overview.thisMonth }}</td>
        </tr>
        <tr>
          <th>Time Logged Last Month</th>
          <td>{{ teamMember.time_log_overview.lastMonth }}</td>
        </tr>
      </tbody>
    </v-simple-table>
  </div>
</template>

<script>
export default {
  props: {
    teamMember: {
      type: Object,
      required: true,
    },
  },
};
</script>